import React from "react";
const Header = () => {
  const logout = async () => {
    localStorage.removeItem("token");
    window.location = "/login";
  };
  return (
    <div className="headera">
      <label for="check">
        <i className="fas fa-bars" id="sidebar_btn"></i>
      </label>
      <div className="left_area">
        <h3>
          Admin <span>panel</span>
        </h3>
      </div>
      <div className="right_area">
        <a href="#" className="logout_btn" onClick={() => logout()}>
          Logout
        </a>
      </div>
    </div>
  );
};

export default Header;
